<template>
	<div class="wrapper">
		<div class="page" v-html="tutorialList[pageSize]"></div>
		<div class="p-footer h-end">
			<button class="btn auto "
			        @click="$router.back()">返回
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'setTutorial',
	data() {
		return {
			pageSize: 1,
			tutorialList: [
				// 设置“群管理”
				'<h2>一、设置“群管理”</h2><melo-data></melo-data><hr/><p>选择我们需要托管的卖货群 </p><p>最多可以设置5个群哦~  </p><b>第一步：点击【立即绑群】</b><p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image1.jpg" style="max-width:100%;" contenteditable="false" width="30%"/></p><b>第二步：搜索卖货群昵称，选择需要开通的群<br/></b><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image2.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>第三步：返回【首页】，点击设置</b><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image3.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>第四步：若使用官方素材，可按此操作</b><p>①选择【推品时间段】</p><p>②点击【官方素材】</p><p>③选择想使用的官方素材组</p><p>④点击【确认跟发】</p><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image4.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>若使用采集素材，要先设置采集群及采集对象</b><p>①选择【采集素材】</p><p>②点击【立即设置】</p><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image5.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>然后选择正确的采集群，每个群各可设置一个</b><p>①搜索采集群的群昵称</p><p>②点击采集群</p><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image6.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>之后再选择正确的采集对象</b><p>①选择【采集群内所有消息】或【采集群内指定消息】</p><p>②若选择【采集群内指定消息】，需选择正确的【采集对象】</p><p>③点击【确定】</p><p>\n' +
				'</p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image7.jpg" style="max-width:100%;" contenteditable="false" width="30%"/><b>最后核实选择正确后，点击【确认跟发】</b><p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file1/image8.jpg" style="max-width:100%;" contenteditable="false" width="30%"/></p><p>\n' +
				'</p>',
				// 设置“朋友圈”
				'<h2 id="pqvxq">二、设置“朋友圈”</h2>' +
				'<hr/><p>可以设置朋友圈跟发功能</p><p>吸引更多的顾客进行下单</p><b>第一步：【朋友圈】跟发功能设置</b><p>①点击【朋友圈】</p><p>②打开【跟发按钮】</p><p>③设置【谁可以看】</p><p>④对预发送朋友圈选择是否推送</p><p>⑤对预发送朋友圈进行【编辑】</p><p></p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file2/image.jpg" style="max-width:100%;" contenteditable="false" width="30%"/>\n' +
				'    <b>第二步：编辑预发送【朋友圈】</b>\n' +
				'    <p>①可对文字进行修改</p>\n' +
				'    <p>②可增删图片</p>\n' +
				'    <p>③【重置】按钮可使朋友圈恢复初始状态</p>\n' +
				'    <p>④设置完成后点击【保存】</p>\n' +
				'    <p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file2/image%20(1).jpg" style="max-width:100%;" contenteditable="false" width="30%"/></p>\n' +
				'<h2 id="gtdlt"><p>\n' +
				'</p></h2>',
				// 设置“入群欢迎语”
					'<h2 id="2tin8">三、设置“入群欢迎语”</h2>\n' +
					'<hr/>\n' +
					'<p>当新顾客进群时👱‍♀️</p>\n' +
					'<p>自动推送欢迎语/店铺链接</p>\n' +
					'<p>让顾客感到受重视❤</p>\n' +
					'<p><b>第一步：点击【入群欢迎语】</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file3/image.jpg" contenteditable="false"\n' +
					'        style="max-width: 100%;" width="30%"/><b>第二步：依次设置【生效群数】 +【欢迎语内容】</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file3/image%20(1).jpg"\n' +
					'        contenteditable="false" style="max-width: 100%;" width="30%"/><b><br/></b></p>\n' +
					'<p><b>第三步：设置【欢迎语内容】</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file3/image%20(2).jpg"\n' +
					'        contenteditable="false" style="max-width: 100%;" width="30%"/><b><br/></b></p>\n' +
					'<p><b>第四步：编辑后保存，返回查看【入群欢迎语】界面</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file3/image%20(3).jpg"\n' +
					'        style="max-width:100%;" contenteditable="false" width="30%"/></p>',
					// 设置“自动回复”
					'<h2 id="ymv2g">四、设置“自动回复”</h2>\n' +
					'<hr/>\n' +
					'<p>可自由设置开启使用/关闭</p>\n' +
					'<p>一般关键词回复主要针对</p>\n' +
					'<p>顾客的常见问题设置自动回复~</p>\n' +
					'<p><b>第一步：点击【关键词回复】</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file4/image.jpg" contenteditable="false"\n' +
					'        style="max-width: 100%;" width="30%"/></p>\n' +
					'<p><b>第二步：打开回复开关，并点击【新增关键词】</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file4/image%20(1).jpg"\n' +
					'        style="max-width:100%;" contenteditable="false" width="30%"/></p>\n' +
					'<p><b>第三步：依次设置红框里的内容</b></p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file4/image%20(2).jpg"\n' +
					'        style="max-width:100%;" contenteditable="false" width="30%"/></p>\n' +
					'<p><b>第四步：编辑关键词对应的内容回复，并点击保存即可</b></p>\n' +
					'<p>①点击【添加】</p>\n' +
					'<p>②编辑相应的内容</p>\n' +
					'<p>③编辑完成后，点击保存</p>\n' +
					'<p><img src="https://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/setTutorial/file4/image%20(3).jpg"\n' +
					'        style="max-width:100%;" contenteditable="false" width="30%"/></p>'
			]
		}
	},
	created() {
		this.pageSize = Number(this.$route.query.pageSize)
	}
}
</script>

<style scoped lang="scss">
@import './index';

.wrapper {
	padding-bottom: 60px;
}
</style>
